import { helpers, required } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";

export default {
  familyIncome: {
    required: helpers.withMessage(validationJson.required, required),
  },
  familyIncomeActivity: {
    nonZero: helpers.withMessage(
      validationJson.required,
      Validator.nonZeroIf("familyIncome"),
    ),
  },
};
