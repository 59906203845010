import Home from "~/themes/credito/components/Home.vue";
import PowerCredit from "~/themes/credito/components/RatePowerCredit.vue";
import PowerCreditUpgrade from "~/themes/credito/components/RatePowerCreditUpgrade.vue";
import EmailPrivacy from "~/components/forms/EmailPrivacy.vue";
import Contacts from "~/components/forms/Contacts.vue";
import PersonalData from "~/components/forms/PersonalData.vue";
import CreditRisk from "~/components/forms/CreditRisk.vue";
import EmployeeLoan from "~/components/forms/EmployeeLoan.vue";
import IncomeData from "~/components/forms/IncomeData.vue";
import FamilyIncome from "~/components/forms/FamilyIncome.vue";
import EmployerData from "~/components/forms/EmployerData.vue";
import LoanProtection from "~/components/forms/LoanProtection.vue";
import LoanProtectionConsent from "~/components/forms/LoanProtectionConsent.vue";
import LoanProtectionWarning from "~/components/forms/LoanProtectionWarning.vue";
import CreditSwitcher from "~/themes/credito/components/CreditSwitcher.vue";
import ClubConsent from "~/components/forms/ClubConsent.vue";
import ClubPlus from "~/components/forms/ClubPlus.vue";
import ClubPlusWarning from "~/components/forms/ClubPlusWarning.vue";
import ClubPlusConsent from "~/components/forms/ClubPlusConsent.vue";
import ClubWarning from "~/components/forms/ClubWarning.vue";
import ClubLight from "~/components/forms/ClubLight.vue";
import DeliveryData from "~/components/forms/DeliveryData.vue";
import IdentityDocData from "~/components/forms/IdentityDocData.vue";
import PaymentData from "~/components/forms/PaymentData.vue";
import PersonalCredit from "~/components/forms/RatePersonalCredit.vue";
import PromoCredit from "~/components/forms/RatePromo.vue";
import PromoLoading from "~/components/forms/PromoLoading.vue";
import PromoConfirm from "~/components/forms/PromoConfirm.vue";
import Promo from "~/components/forms/Promo.vue";
import Partner from "~/components/forms/Partner.vue";
import PromoTerms from "~/components/forms/PromoTerms.vue";
import MetlifeProtection from "~/components/forms/MetlifeProtection.vue";
import MetlifeProtectionConsent from "~/components/forms/MetlifeProtectionConsent.vue";
import MetlifeProtectionWarning from "~/components/forms/MetlifeProtectionWarning.vue";
import Typ from "~/components/typ/Typ.vue";
import TypClub from "~/components/typ/TypClub.vue";
import TypClubKO from "~/components/typ/TypClubKO.vue";
import TypCQ from "~/components/typ/TypCQ.vue";
import TypCQMetlife from "~/components/typ/TypCQMetlife.vue";
import TypMetlifeKO from "~/components/typ/TypMetlifeKO.vue";
import TypMetlifeOK from "~/components/typ/TypMetlifeOK.vue";
import TypML from "~/components/typ/TypML.vue";

export default {
  Home,
  EmailPrivacy,
  Contacts,
  PersonalData,
  CreditRisk,
  EmployeeLoan,
  IncomeData,
  FamilyIncome,
  EmployerData,
  LoanProtection,
  LoanProtectionConsent,
  LoanProtectionWarning,
  ClubConsent,
  ClubWarning,
  ClubPlus,
  ClubPlusWarning,
  ClubPlusConsent,
  ClubLight,
  CreditSwitcher,
  DeliveryData,
  IdentityDocData,
  PaymentData,
  PowerCredit,
  PowerCreditUpgrade,
  PersonalCredit,
  PromoCredit,
  PromoLoading,
  PromoConfirm,
  PromoTerms,
  Partner,
  Promo,
  MetlifeProtection,
  MetlifeProtectionConsent,
  MetlifeProtectionWarning,
  Typ,
  TypClub,
  TypClubKO,
  TypCQ,
  TypCQMetlife,
  TypMetlifeKO,
  TypMetlifeOK,
  TypML,
};
