import { helpers, required, requiredIf, sameAs } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";

export default (gPackage: boolean) => {
  return {
    consentMain: {
      required: helpers.withMessage(validationJson.required, required),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
    consent1: {
      required: helpers.withMessage(validationJson.required, required),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
    consent2: {
      required: helpers.withMessage(validationJson.required, required),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
    consent3: {
      required: helpers.withMessage(validationJson.required, required),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
    consent4: {
      required: helpers.withMessage(
        validationJson.required,
        requiredIf(function () {
          return gPackage;
        }),
      ),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
    consent5: {
      required: helpers.withMessage(
        validationJson.required,
        requiredIf(function () {
          return gPackage;
        }),
      ),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
    consent6: {
      required: helpers.withMessage(
        validationJson.required,
        requiredIf(function () {
          return gPackage;
        }),
      ),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
    consent7: {
      required: helpers.withMessage(
        validationJson.required,
        requiredIf(function () {
          return gPackage;
        }),
      ),
      sameAsRawValue: helpers.withMessage(
        validationJson.subscription_is_not_possible,
        sameAs(true),
      ),
    },
  };
};
