import { helpers, required } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";

export default {
  consent_main: {
    required: helpers.withMessage(validationJson.required, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent1: {
    required: helpers.withMessage(validationJson.required, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent2: {
    required: helpers.withMessage(validationJson.required, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent3: {
    required: helpers.withMessage(validationJson.required, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent4: {
    required: helpers.withMessage(validationJson.metlife_consent, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent5: {
    required: helpers.withMessage(validationJson.metlife_consent, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent6: {
    required: helpers.withMessage(validationJson.metlife_consent, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent7: {
    required: helpers.withMessage(validationJson.metlife_consent, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
  consent8: {
    required: helpers.withMessage(validationJson.metlife_consent, required),
    mustBeTrue: helpers.withMessage(
      validationJson.metlife_consent,
      Validator.mustBeTrue,
    ),
  },
};
