<script setup lang="ts">
import ErrorMessage from "~/components/ui/ErrorMessage.vue";
import { maritalStatusOptions } from "~/utils/values";

defineProps({
  hasError: { type: Boolean, default: false },
  errorMessage: { type: String, default: "" },
});

const modelValue = defineModel<any>();

const updateModel = (value: any) => {
  modelValue.value = value;
};

</script>

<template>
  <div class="grid grid-cols-3 md:grid-cols-6 pt-4 lg:max-w-3xl mx-auto">
    <button
      v-for="{ icon, label, value } in maritalStatusOptions"
      :key="value"
      class="marital-status"
      :data-testid="`maritalStatus${label}`"
      @click="updateModel(value)"
      @keydown="(e) => { e.key === 'Enter' && updateModel(value) }"
      :aria-selected="value == modelValue"
      type="button"
    >
      <div
        class="option"
        :class="[value == modelValue ? 'bg-yellow' : 'bg-gray-1']"
      >
        <div class="h-12">
          <component :is="icon" size="48px"></component>
        </div>
      </div>
      <span class="block text-center" v-html="label"></span>
    </button>
  </div>
  <div class="text-center mb-10">
    <ErrorMessage v-if="hasError" :message="errorMessage" />
  </div>
</template>

<style scoped>
.marital-status {
  @apply cursor-pointer flex flex-col rounded-lg px-0 py-2 items-center;
}

.marital-status .option {
  @apply h-16 w-16 mx-auto rounded-full flex items-center justify-center;
}
</style>
