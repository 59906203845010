<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import FormCard from "~/components/ui/FormCard.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";

const emit = defineEmits(["init", "submit", "decline"]);

defineProps({
  progress: {
    type: Number,
    required: true,
  },
});
onMounted(() => emit("init"));
</script>

<template>
  <div>
    <BaseForm>
      <template #upper>
        <ProgressBar :value="progress" />
        <FormCard
          title="Scegli la polizza facoltativa: 1° mese gratis!"
          @submit="emit('submit')"
        >
          <p class="text-red font-bold mb-4">
            Non perdere questa occasione! Proteggiti da circostanze impreviste
            della vita e rimani tranquillo contro incertezze future. Per te il
            primo mese di protezione è gratuito con la polizza infortuni
            «MetLife Best Care» di MetLife Italia.
          </p>
          <p class="mb-4">
            La soluzione che ti proponiamo, con soli 14,90 euro di premio
            mensile, ti protegge in caso di:
          </p>
          <ul class="list-disc ml-4 mb-4">
            <li>
              <b>Ricovero:</b> ricevi una somma giornaliera pari a 100€ per ogni
              giorno di degenza in seguito ad infortunio
            </li>
            <li>
              <b>Convalescenza post ricovero:</b> beneficia di una somma
              giornaliera pari a 50€ per ogni giorno di convalescenza
            </li>
            <li>
              <b>Ricovero in reparto di terapia intensiva:</b> ricevi una somma
              pari a 100€ per ogni giorno di ricovero
            </li>
            <li>
              <b>Fratture, ustioni e lussazioni:</b> ricevi un indennizzo pari a
              500€ a seguito di questi infortuni.
            </li>
          </ul>
          <p class="mb-4">
            Consulta il
            <a class="underline" :href="METLIFE_INFO_URL" target="_blank"
              >set informativo</a
            >
            per conoscere nel dettaglio tutte le caratteristiche del prodotto.
          </p>

          <template #link>
            <div slot="link" class="text-center text-gray-700 mt-4 mb-8">
              <button
                class="underline uppercase"
                data-testid="decline"
                @click="emit('decline')"
              >
                Continua senza polizza
              </button>
            </div>
          </template>
        </FormCard>
      </template>
    </BaseForm>
  </div>
</template>

<style scoped></style>
