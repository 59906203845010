<script setup lang="ts">
import IbanCalendar from "@/components/icons/IbanCalendar.vue";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";
import type { IValue } from "~/interfaces";

defineComponent({
  name: "RadioButton",
});

const props = defineProps({
  values: {
    type: Array as () => IValue[],
    default: () => [],
    required: false,
  },
  name: {
    type: String,
    required: false,
  },
  class: {
    type: String,
    required: false,
    default: undefined,
  },
  calendar: {
    type: Boolean,
    required: false,
    default: false,
  },
  error: {
    type: Boolean,
    required: false,
    default: false,
  },
  errorMessage: {
    type: String,
    required: false,
    default: "",
  },
  unselect: {
    type: Boolean,
    required: false,
    default: false,
  },
  labelId: {
    type: String,
    required: false,
    default: ''
  }
});

const slots = useSlots();

const selectedOption = defineModel<any>();

const emit = defineEmits(["value"]);

const uid = getCurrentInstance()?.uid;

const handleSelection = (value: any) => {
  if (props.unselect) {
    selectedOption.value = selectedOption.value === value ? null : value;
    emit("value", null);
  } else {
    selectedOption.value = value;
    emit("value", value);
  }
};

const isMobile = computed(() => {
  return window.innerWidth < 768;
})
</script>

<template>
  <div class="primary">
    <div :class="[slots.default && 'flex-col md:flex-row flex md:gap-5']">
      <label v-if="isMobile" :for="`radiogroup-${uid}`">
        <slot />
      </label>
      <div class="radio-button-container" role="radiogroup" :id="`radiogroup-${uid}`" :aria-labelledby="labelId">
        <div
          v-for="(item, index) in values"
          :key="index"
          :class="[
            calendar ? 'calendarRadio' : 'normalRadio',
            props.class,
            selectedOption == item.value && !calendar ? 'primary' : '',
          ]"
          role="radio"
          tabindex="0"
          :aria-checked="selectedOption == item.value"
          @click="() => handleSelection(item.value)"
          @keydown="e => e.key === 'Enter' && handleSelection(item.value)"
        >
          <IbanCalendar
            v-if="calendar"
            class="background-svg z-10"
            :color="selectedOption == item.value ? 'var(--yellow)' : '#fff'"
          />
          <input
            :id="`radioComponent${uid}${index}`"
            v-model="selectedOption"
            type="radio"
            :value="item.value"
            class="hidden"
          />
          <label
            class="block text-lg font-semibold cursor-pointer z-20  pointer-events-none"
            :class="[calendar && 'mt-[5px]']"
            :for="`radioComponent${uid}${index}`"
            >{{ item.label }}
          </label>
        </div>
      </div>
      <label v-if="!isMobile" :for="`radiogroup-${uid}`">
        <slot />
      </label>
    </div>
    <ErrorMessage v-if="error" :small="false" :message="errorMessage" />
  </div>
</template>

<style scoped></style>
