<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import FormCard from "~/components/ui/FormCard.vue";
import RadioButton from "~/components/inputs/RadioButton.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";

import MetlifeProtectionConsent from "~/rules/metlifeProtectionConsent";
import Validator from "~/utils/validator";
const locale = await getLocale("forms", "metlife_protection_consent");
import { subscription_is_not_possible } from "~/static/locale/it/validation.json";

import { useVuelidate } from "@vuelidate/core";
import { METLIFE_INFO_URL, METLIFE_PRIVACY_URL } from "~/utils/constants";

defineProps({
  progress: { type: Number, required: true },
});

const consent_main = useState<boolean | null>(
  "metlife_consent_main",
  () => null,
);
const consent1 = useState<boolean | null>("metlifeConsent1", () => null);
const consent2 = useState<boolean | null>("metlifeConsent2", () => null);
const consent3 = useState<boolean | null>("metlifeConsent3", () => null);
const consent4 = useState<boolean | null>("metlifeConsent4", () => null);
const consent5 = useState<boolean | null>("metlifeConsent5", () => null);
const consent6 = useState<boolean | null>("metlifeConsent6", () => null);
const consent7 = useState<boolean | null>("metlifeConsent7", () => null);
const consent8 = useState<boolean | null>("metlifeConsent8", () => null);

const v$ = useVuelidate(MetlifeProtectionConsent, {
  consent_main,
  consent1,
  consent2,
  consent3,
  consent4,
  consent5,
  consent6,
  consent7,
  consent8,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const emit = defineEmits(["init", "submit", "decline"]);

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"));
</script>

<template>
  <div>
    <BaseForm @submit.prevent="submit">
      <template #upper>
        <ProgressBar :value="progress" />
        <FormCard
          :title="locale.form_card.title"
          :submit-text="locale.form_card.submit_text"
          :submit-disabled="v$.$invalid"
        >
          <p class="font-bold mb-4">
            {{ locale.form_card.paragraph1 }}
          </p>
          <p class="mb-4">
            {{ locale.form_card.paragraph2 }}
          </p>

          <div class="flex flex-col gap-4 items-start text-left">
            <RadioButton
              v-model="consent_main"
              :values="yesNoOptions"
              :error="
                v$.consent_main.$error ||
                (consent_main !== null && !consent_main)
              "
              :error-message="
                v$.consent_main.$error
                  ? Validator.message(v$.consent_main)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsentMain"
            >
              {{ locale.consent_items.consent_main }}
            </RadioButton>
            <RadioButton
              v-model="consent1"
              :values="yesNoOptions"
              :error="v$.consent1.$error || (consent1 !== null && !consent1)"
              :error-message="
                v$.consent1.$error
                  ? Validator.message(v$.consent1)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent1"
            >
              {{ locale.consent_items.consent1 }}
            </RadioButton>
            <RadioButton
              v-model="consent2"
              :values="yesNoOptions"
              :error="v$.consent2.$error || (consent2 !== null && !consent2)"
              :error-message="
                v$.consent2.$error
                  ? Validator.message(v$.consent2)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent2"
            >
              {{ locale.consent_items.consent2 }}
            </RadioButton>
            <RadioButton
              v-model="consent3"
              :values="yesNoOptions"
              :error="v$.consent3.$error || (consent3 !== null && !consent3)"
              :error-message="
                v$.consent3.$error
                  ? Validator.message(v$.consent3)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent3"
            >
              {{ locale.consent_items.consent3 }}
            </RadioButton>

            <p class="font-bold mb-4">
              {{ locale.form_card.paragraph3 }}
            </p>

            <RadioButton
              v-model="consent4"
              :values="okOption"
              :error="v$.consent4.$error || (consent4 !== null && !consent4)"
              :error-message="
                v$.consent4.$error
                  ? Validator.message(v$.consent4)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent4"
              :unselect="true"
            >
              <div
                v-html="
                  locale.consent_items.consent4.replace('%d', METLIFE_INFO_URL)
                "
              ></div>
            </RadioButton>
            <RadioButton
              v-model="consent5"
              :values="okOption"
              :error="v$.consent5.$error || (consent5 !== null && !consent5)"
              :error-message="
                v$.consent5.$error
                  ? Validator.message(v$.consent5)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent5"
              :unselect="true"
            >
              {{ locale.consent_items.consent5 }}
            </RadioButton>
            <RadioButton
              v-model="consent6"
              :values="okOption"
              :error="v$.consent6.$error || (consent6 !== null && !consent6)"
              :error-message="
                v$.consent6.$error
                  ? Validator.message(v$.consent6)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent6"
              :unselect="true"
            >
              <div
                v-html="
                  locale.consent_items.consent6.replace(
                    '%d',
                    METLIFE_PRIVACY_URL,
                  )
                "
              ></div>
            </RadioButton>
            <RadioButton
              v-model="consent7"
              :values="okOption"
              :error="v$.consent7.$error || (consent7 !== null && !consent7)"
              :error-message="
                v$.consent7.$error
                  ? Validator.message(v$.consent7)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent7"
              :unselect="true"
            >
              {{ locale.consent_items.consent7 }}
            </RadioButton>
            <p class="font-bold my-4 uppercase">
              {{ locale.form_card.paragraph4 }}
            </p>
            <RadioButton
              v-model="consent8"
              :values="okOption"
              :error="v$.consent8.$error || (consent8 !== null && !consent8)"
              :error-message="
                v$.consent8.$error
                  ? Validator.message(v$.consent8)
                  : subscription_is_not_possible
              "
              data-testid="metlifeConsent8"
              :unselect="true"
            >
              <div v-html="locale.consent_items.consent8"></div>
            </RadioButton>
          </div>

          <template #link>
            <div class="text-center text-gray-700 mt-4 mb-8">
              <button
                class="underline uppercase inline"
                @click="emit('decline')"
                >{{ locale.form_card.decline }}</button
              >
            </div>
          </template>
        </FormCard>
      </template>
    </BaseForm>
  </div>
</template>

<style scoped></style>
