import { helpers, required } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";

export default {
  consent_club1: {
    required: helpers.withMessage(validationJson.club_consent_error, required),
    mustBeTrue: helpers.withMessage(
      validationJson.club_consent_error,
      Validator.mustBeTrue,
    ),
  },
  consent_club2: {
    required: helpers.withMessage(validationJson.club_consent_error, required),
    mustBeTrue: helpers.withMessage(
      validationJson.club_consent_error,
      Validator.mustBeTrue,
    ),
  },
  consent_club3: {
    required: helpers.withMessage(validationJson.club_consent_error, required),
    mustBeTrue: helpers.withMessage(
      validationJson.club_consent_error,
      Validator.mustBeTrue,
    ),
  },
  consent_club4: {
    required: helpers.withMessage(validationJson.club_consent_error, required),
    mustBeTrue: helpers.withMessage(
      validationJson.club_consent_error,
      Validator.mustBeTrue,
    ),
  },
};
