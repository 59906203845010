<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";

import ClubOptionalConsents from "~/components/inputs/ClubOptionalConsents.vue";

const local = await getLocale("forms", "club_consent");
import { CLUB_PRIVACY_URL } from "~/utils/constants";

const emit = defineEmits(["init", "submit", "decline"]);

defineComponent({
  name: "ClubConsent",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  installment: { type: String, required: true },
});

const clubConsentMain = useState<boolean | null>("clubConsentMain", () => null);
const clubConsent1 = useState<boolean | null>("clubConsent1", () => null);
const clubConsent2 = useState<boolean | null>("clubConsent2", () => null);
const clubConsent3 = useState<boolean | null>("clubConsent3", () => null);
const clubConsent4 = useState<boolean | null>("clubConsent4", () => null);
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" class="leading-6" @submit="emit('submit')">
        <p
          class="mb-4"
          v-html="
            local.paragraph_1.replace('%d', `${installment}`)
          "
        ></p>
        <p
          class="mb-4"
          v-html="local.paragraph_2.replace('%d', CLUB_PRIVACY_URL)"
        ></p>

        <ClubOptionalConsents
          v-model:main-consent="clubConsentMain"
          v-model:consent1="clubConsent1"
          v-model:consent2="clubConsent2"
          v-model:consent3="clubConsent3"
          v-model:consent4="clubConsent4"
        />
      </FormCard>

      <div class="text-center text-gray-700 mt-4 mb-8">
        <button
          class="underline"
          data-testid="decline"
          @click="() => $emit('decline')"
        >
          {{ local.continue_without_club }}
        </button>
      </div>
      <div class="spacer h-6"></div>
    </template>

    <template #bottom-out>
      <p class="italic px-4">{{ local.disclaimer }}</p>
    </template>
  </BaseForm>
</template>

<style scoped></style>
