<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
const locale = await getLocale("forms", "club_warning");
import { computed } from 'vue';
import { useState } from '#app';

defineComponent({
  name: "ClubWarning",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
});

const name = useState("name", () => 'Nome utente');

const dynamicDescription = computed(() => {
  return `${name.value}, ${locale.description2}`;
});

const emit = defineEmits(["init", "submit", "decline"]);

onMounted(() => emit("init"));

</script>

<template>

  <BaseForm>

    <template #upper>

      <ProgressBar :value="progress" />

      <FormCard :title="title" @submit="emit('submit')">

        <div class="w-full grid grid-col-1 md:grid-cols-12 gap-4">

          <p class="md:col-span-8 md:py-4 -mt-2" v-html="locale.description"></p>

          <div class="flex gap-4 md:col-span-4 border rounded-xl p-3">
            <img src="../../public/images/static/EPdA-Logo-2025_rgb.png" class="h-24" alt="logo prodotto dell'anno">
            <span class="-ms-3 -mt-1">*</span>
            <p class="-mt-1 md:-mt-1.5 text-sm md:text-base" v-html="dynamicDescription"></p>
          </div>

        </div>

        <template #link>
          <div class="text-center text-gray-700 mt-4 mb-8">
            <button data-testid="decline" class="underline" @click="emit('decline')">
              {{ locale.proceed_without_club }}
            </button>
          </div>
        </template>

      </FormCard>

    </template>

    <template #bottom-out>

      <p class="italic px-4">{{ locale.disclaimer }}</p>

    </template>

  </BaseForm>

</template>

<style scoped>
@media screen and (max-width: 639px) {
  div :deep(.form-header) {
    font-size: 1rem !important;
  }
}
</style>
