<script setup lang="ts">
import type { IRate, IValue } from "~/interfaces";

import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import RadioButton from "~/components/inputs/RadioButton.vue";
import InputFloatingLabel from "~/components/inputs/InputFloatingLabel.vue";
import Accordion from "~/components/ui/Accordion.vue";

import Validator from "~/utils/validator";

import { useVuelidate } from "@vuelidate/core";
const locale = await getLocale("forms", "payment_data");
import paymentDataFormRules from "~/rules/paymentData";
import { debitDayPowerOptions } from "~/utils/values";
import { getModelPromoKO } from "~/utils/promo";

defineComponent({
  name: "PaymentData",
});

const { installationDebitDates } = defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  politicallyExposedOptions: {
    type: Array as () => IValue[],
    default: () => [...yesNoOptions].reverse(),
  },
  prepaidCards: { type: Boolean, default: false },
  submitText: { type: String, required: true },
  showHelperText: { type: Function, required: true },
  installationDebitDates: { type: Array<IValue> },
});

const installmentDebitDay = useState<number>("installmentDebitDay");
const politicallyExposedPerson = useState<boolean>("politicallyExposedPerson");
const iban = useState<string | null>("iban", () => null);
const forgotIbanAccordion = ref<any>();
const modelKO = ref<IRate | null>(getModelPromoKO());

// Set default value for installmentDebitDay
if (installationDebitDates?.length === 1) {
  setStateValue("installmentDebitDay", installationDebitDates[0].value);
}

const toggleAccordion = () => {
  forgotIbanAccordion.value.toggleAccordion();
};

const v$ = useVuelidate(paymentDataFormRules, {
  installmentDebitDay,
  politicallyExposedPerson,
  iban,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const emit = defineEmits(["init", "submit"]);
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm class="payment-data-container">
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard
        :title="title"
        :submit-text="submitText"
        @submit="validate() ? $emit('submit') : ''"
      >
        <div
          class="text-sm w-full md:w-1/2 xl:pt-2 mb-6 md:mb-0 sm:flex flex-col text-gray-700"
        >
          <div class="md:pr-4">
            {{ locale.installment_debit_day }}
          </div>
          <div class="flex mx-auto md:ml-0 justify-start mt-2 w-full">
            <RadioButton
              v-model="installmentDebitDay"
              :calendar="true"
              :values="installationDebitDates"
              :error="v$.installmentDebitDay.$error"
              :error-message="Validator.message(v$.installmentDebitDay)"
              data-testid="installmentDebitDay"
            />
          </div>
        </div>
        <div class="w-full md:w-1/2 text-gray-700 flex flex-col">
          <InputFloatingLabel
            v-model="iban"
            :label="locale.iban_current_account"
            type="text"
            :mask="ibanMask"
            :error="v$.iban.$error"
            :error-message="Validator.message(v$.iban)"
            test-id="iban"
            @error="(e: any) => console.log('error')"
          />
          <small v-if="!prepaidCards" class="block mt-2 mb-1">
            {{ locale.prepaid_no_accepted }}
          </small>
          <div class="text-xs">
            <span @click="toggleAccordion" @keydown="e => {e.key == 'Enter' && toggleAccordion()}" v-html="locale.forgot_iban"></span>
          </div>
        </div>
        <div class="w-full">
          <div class="text-xs">
            <Accordion ref="forgotIbanAccordion">
              <template #items>
                <div class="border-t mt-5 pt-5 flex flex-wrap">
                  <div class="w-full md:w-1/3">
                    <h3 class="font-semibold mb-4 text-lg">
                      {{ locale.find_iban_code }}
                    </h3>
                  </div>
                  <div class="w-full md:w-1/3">
                    <p class="text-sm">
                      {{ locale.you_can_find_iban_code }}
                    </p>
                    <ul class="list-disc px-6 my-2 text-sm">
                      <li>
                        {{ locale.banking_service }}
                      </li>
                      <li>
                        {{ locale.statement_extract }}
                      </li>
                      <li>{{ locale.pay_stub }}</li>
                    </ul>
                  </div>
                  <div class="w-full md:w-1/3">
                    <div class="flex flex-col text-sm mb-4">
                      {{ locale.iban_example }} <br />
                      {{ locale.iban_example_code }}
                    </div>
                    <span class="block pb-4 text-xs">
                      {{ locale.code_format }}
                    </span>
                  </div>
                </div>
              </template>
            </Accordion>
          </div>
        </div>

        <div class="w-full flex mt-8 sm:mt-4 flex-col mb-4">
          <span class="mb-2 text-sm">{{ locale.politically_exposed }}</span>
          <RadioButton
            v-model="politicallyExposedPerson"
            :values="politicallyExposedOptions"
            :error="v$.politicallyExposedPerson.$error"
            :error-message="Validator.message(v$.politicallyExposedPerson)"
            data-testid="politicallyExposedPerson"
          >
          </RadioButton>
          <small
            v-if="politicallyExposedPerson"
            class="block mt-2 mb-1 text-red text-[13px]"
          >
            {{ locale.verify_pep_definition }}
          </small>
        </div>
        <div>
          <Accordion>
            <div class="text-xs" v-html="locale.pep_definition"></div>
            <template #items>
              <div
                class="py-5 text-gray-600 border-t border-b mt-5"
                v-html="locale.pep_explanation"
              ></div>
            </template>
          </Accordion>
        </div>
        <div v-if="showHelperText() && modelKO" class="w-full mt-6">
          <p class="text-sm text-left">
            Cliccando su Invia richiesta prendo atto che il mio profilo non
            soddisfa i
            <button
              type="button"
              class="underline inline"
              @click="updateStateValue('promoModal', true)"
            >
              Termini e condizioni
            </button>
            per richiedere l’offerta promo e che invio richiesta di
            {{ modelKO.imp_finanz_text }} TAEG {{ modelKO.taeg }}%.
          </p>
        </div>
      </FormCard>
      <div class="spacer h-6"></div>
    </template>
  </BaseForm>
</template>

<style scoped></style>
