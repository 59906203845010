<script setup lang="ts">
import Button from "~/components/inputs/Button.vue";
import FormHeader from "~/components/ui/FormHeader.vue";

defineProps({
  title: { type: String, default: "" },
  submitText: { type: String, default: "CONTINUA" },
  submitDisabled: { type: Boolean, default: false },
  hasSubmit: { type: Boolean, default: true },
  headerClass: { type: String, required: false },
});

const emit = defineEmits(["submit"]);

const keyEvent = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    return;
  }
};

const buttonKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    event.preventDefault();
    emit('submit', event)
    return;
  }
}

const uid = getCurrentInstance()?.uid;

</script>

<template>
  <div>
    <form
      :id="`form-card-${uid}`"
      class="form-card primary px-4 mx-auto lg:container py-4 text-gray-700"
      @submit.prevent="emit('submit', $event)"
      @keydown="keyEvent"
    >
      <FormHeader :header-class="headerClass">{{ title }}</FormHeader>
      <slot name="before"></slot>
      <div
        class="form-body p-4 pb-6 sm:px-6 md:px-8 flex flex-wrap items-start"
      >
        <slot></slot>
      </div>
      <slot name="after"></slot>
      <div v-if="hasSubmit" class="form-footer text-center relative">
        <Button
          :for="`form-card-${uid}`"
          data-testid="formSubmit"
          class="!px-16"
          type="submit"
          @keydown="buttonKeyDown"
          :disabled="submitDisabled"
        >
          {{ submitText }}
        </Button>
      </div>
    </form>
    <slot name="link"></slot>
  </div>
</template>

<style scoped></style>
