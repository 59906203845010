<script setup lang="ts">
import type { Ref } from "vue";
import type { ICity, IValue } from "~/interfaces";

import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import InputFloatingLabel from "~/components/inputs/InputFloatingLabel.vue";
import AutoComplete from "~/components/inputs/AutoComplete.vue";
import Select from "~/components/inputs/Select.vue";
import SelectDate from "~/components/inputs/SelectDate.vue";
import RadioButton from "~/components/inputs/RadioButton.vue";

import Validator from "~/utils/validator";

const locale = await getLocale("forms", "delivery_data");
import { useVuelidate } from "@vuelidate/core";
import deliveryFormRules from "~/rules/deliveryData";
import jsonCities from "~/data/cities.json";
import { helpers, requiredIf } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";

const runtimeConfig = useRuntimeConfig();

defineComponent({
  name: "DeliveryData",
});

const { showHousingType } = defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  residentSinceStartYear: { type: Number, required: true },
  showHousingType: { type: Boolean, default: false },
});

const emit = defineEmits(["init", "submit", "goBack"]);

const address = useState<string | null>("address", () => null);
const addressNumber = useState<string | null>("addressNumber", () => null);
const addressCity = useState<string | null>("addressCity", () => null);
const addressCap = useState<string | null>("addressCap", () => null);
const residenceSince = useState<string | null>("residenceSince", () => null);
const housingType = useState<number | null>("housingType", () => null);
const monthlyRent = useState<string | null>("monthlyRent", () => null);
const sameAddresses = useState<boolean | null>("sameAddresses", () => true);
const address2 = useState<string | null>("address2", () => null);
const addressNumber2 = useState<string | null>("addressNumber2", () => null);
const addressCity2 = useState<string | null>("addressCity2", () => null);
const addressCap2 = useState<string | null>("addressCap2", () => null);

const comunes: Ref<IValue[]> = ref([]);
const postalCodes: Ref<IValue[]> = ref([]);
const postalCodes2: Ref<IValue[]> = ref([]);

onMounted(() => {
  jsonCities.forEach((city: ICity) => {
    comunes.value.push({
      value: city.Prov,
      label: `${city.Citta} (${city.Prov})`,
    });
  });
});

const showMonthlyRent = computed(() => {
  return housingType.value === 1;
});

watch(housingType, async () => {
  monthlyRent.value = "";
});

watch(addressCity, async (newValue) => {
  addressCap.value = null;
  postalCodes.value = [];

  const city = jsonCities.find((city) => city.Citta == newValue?.slice(0, -5));

  if (city) {
    if (city.Multicap) {
      postalCodes.value.push({
        value: null,
        label: locale.postal_code,
        disabled: true,
      });
      city.Multicap.split(",").map((cap: string) => {
        postalCodes.value.push({
          value: cap.padStart(5, "0"),
          label: cap.padStart(5, "0"),
        });
      });
    } else {
      addressCap.value = city.Cap.padStart(5, "0");
    }
  }
});

watch(addressCity2, async (newValue) => {
  addressCap2.value = null;
  postalCodes2.value = [];

  const city = jsonCities.find((city) => city.Citta == newValue?.slice(0, -5));

  if (city) {
    if (city.Multicap) {
      postalCodes2.value.push({
        value: null,
        label: locale.postal_code,
        disabled: true,
      });
      city.Multicap.split(",").map((cap: string) => {
        postalCodes2.value.push({
          value: cap.padStart(5, "0"),
          label: cap.padStart(5, "0"),
        });
      });
    } else {
      addressCap2.value = city.Cap.padStart(5, "0");
    }
  }
});

const v$ = useVuelidate(deliveryFormRules, {
  address,
  addressNumber,
  addressCity,
  addressCap,
  residenceSince,
  sameAddresses,
  housingType,
  monthlyRent,
  address2,
  addressNumber2,
  addressCity2,
  addressCap2,
  showHousingType,
  showMonthlyRent,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="submit">
        <div class="w-full sm:w-3/6 sm:pr-4 mb-2">
          <InputFloatingLabel
            v-model="address"
            :label="locale.residence_address"
            type="text"
            :mask="addressMask"
            :error="v$.address.$error"
            :error-message="Validator.message(v$.address)"
            test-id="address"
          />
        </div>
        <div class="w-1/3 sm:w-1/6 mb-2">
          <InputFloatingLabel
            v-model="addressNumber"
            :label="locale.address_number"
            type="text"
            :mask="addressStreetNumberMask"
            :error="v$.addressNumber.$error"
            :error-message="Validator.message(v$.addressNumber)"
            test-id="addressNumber"
          />
        </div>
        <div class="w-2/3 sm:w-2/6 pl-4 mb-2">
          <AutoComplete
            v-if="comunes.length"
            v-model="addressCity"
            :clear-incomplete="true"
            :values="comunes"
            :label="locale.residence_city"
            uppercase
            :error="v$.addressCity.$error"
            :error-message="Validator.message(v$.addressCity)"
            test-id="addressCity"
          />
        </div>
        <div class="w-1/2 sm:pr-4 mb-2 sm:mt-6">
          <Select
            v-if="postalCodes.length"
            v-model="addressCap"
            :values="postalCodes"
            :label="locale.postal_code"
            floating
            disable-zero-value
            :error="v$.addressCap.$error"
            :error-message="Validator.message(v$.addressCap)"
            test-id="addressCap"
          />
          <InputFloatingLabel
            v-else
            v-model="addressCap"
            :label="locale.postal_code"
            type="tel"
            :error="v$.addressCap.$error"
            :error-message="Validator.message(v$.addressCap)"
            disabled
            test-id="addressCap"
          />
        </div>
        <div class="w-full sm:w-1/2 mb-2">
          <label class="text-xs">{{ locale.residence_since }}</label>
          <SelectDate
            v-model="residenceSince"
            :show-day="false"
            :start-year="residentSinceStartYear"
            :error="v$.residenceSince.$error"
            :error-message="Validator.message(v$.residenceSince)"
            test-id="residenceSince"
          />
        </div>
        <div v-if="showHousingType" class="w-full sm:w-1/2 sm:pr-4 mb-2">
          <Select
            v-model="housingType"
            :values="housingTypeOptions"
            :label="locale.housing_type"
            disable-zero-value
            floating
            :error="v$.housingType.$error"
            :error-message="Validator.message(v$.housingType)"
            test-id="housingType"
          />
        </div>
        <div v-if="showMonthlyRent" class="w-full sm:w-1/2 mb-2">
          <InputFloatingLabel
            v-model="monthlyRent"
            :label="locale.monthly_rent"
            type="tel"
            :mask="currencyMask"
            :error="v$.monthlyRent.$error"
            :error-message="Validator.message(v$.monthlyRent)"
            test-id="monthlyRent"
          />
        </div>
        <div class="w-full mb-4">
          <label class="text-xs">{{
            locale.address_match_with_residence
          }}</label>
          <RadioButton
            v-model="sameAddresses"
            :values="yesNoOptions"
            :error="v$.sameAddresses.$error"
            :error-message="Validator.message(v$.sameAddresses)"
            data-testid="sameAddresses"
          />
        </div>
        <template #after>
          <div
            v-if="!sameAddresses && sameAddresses !== null"
            class="mt-2 px-4 sm:px-6 md:px-8 pb-12 rounded-b-lg flex flex-wrap"
          >
            <div class="w-full sm:w-3/6 sm:pr-4 mb-2">
              <InputFloatingLabel
                v-model="address2"
                :label="locale.domicile_address"
                type="text"
                :mask="addressMask"
                :error="v$.address2.$error"
                :error-message="Validator.message(v$.address2)"
                test-id="address2"
              />
            </div>
            <div class="w-1/3 sm:w-1/6 mb-2">
              <InputFloatingLabel
                v-model="addressNumber2"
                :label="locale.address_number"
                type="text"
                :mask="addressStreetNumberMask"
                :error="v$.addressNumber2.$error"
                :error-message="Validator.message(v$.addressNumber2)"
                test-id="addressNumber2"
              />
            </div>
            <div class="w-2/3 sm:w-2/6 pl-4 mb-2">
              <AutoComplete
                v-if="comunes.length"
                v-model="addressCity2"
                :clear-incomplete="true"
                :values="comunes"
                :label="locale.domicile_city"
                uppercase
                :error="v$.addressCity2.$error"
                :error-message="Validator.message(v$.addressCity2)"
                test-id="addressCity2"
              />
            </div>
            <div class="w-1/2 sm:pr-4 mb-2">
              <Select
                v-if="postalCodes2.length"
                v-model="addressCap2"
                :values="postalCodes2"
                :label="locale.postal_code"
                floating
                disable-zero-value
                :error="v$.addressCap2.$error"
                :error-message="Validator.message(v$.addressCap2)"
                test-id="addressCap2"
              />
              <InputFloatingLabel
                v-else
                v-model="addressCap2"
                :label="locale.postal_code"
                type="tel"
                :error="v$.addressCap2.$error"
                :error-message="Validator.message(v$.addressCap2)"
                disabled
                test-id="addressCap2"
              />
            </div>
          </div>
        </template>
        <template #link>
          <BackButton :event="`goBack`" @go-back="$emit('goBack')" />
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
