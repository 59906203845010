<script setup lang="ts">
import FormCard from "~/components/ui/FormCard.vue";
import MaritalStatus from "~/components/inputs/MaritalStatus.vue";
import Select from "~/components/inputs/Select.vue";
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import SelectDate from "~/components/inputs/SelectDate.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import citizenshipOptions from "~/data/citizenship.json";

import Validator from "~/utils/validator";

import { useVuelidate } from "@vuelidate/core";
import personalDataFormRules from "~/rules/personalData";
const locale = await getLocale("forms", "personal_data");
import { helpers, requiredIf } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";

defineComponent({
  name: "PersonalData",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "goBack"]);

const citizenship = useState<string | null>("citizenship", () => null);
const stayFrom = useState<string | null>("stayFrom", () => null);
const stayTo = useState<string | null>("stayTo", () => null);
const maritalStatus = useState<number | null>("maritalStatus", () => null);

const showStayFrom = useState<boolean>("showStayFrom", () => false);
const showStayTo = useState<boolean>("showStayTo", () => false);

watch(citizenship, (newValue) => {
  const option = citizenshipOptions.find((item) => item.value === newValue);

  if (option) {
    showStayFrom.value = !!option.stayFrom;
    showStayTo.value = !!option.stayTo;
  } else {
    showStayFrom.value = false;
    showStayTo.value = false;
  }
});

// Reset forms
watch(citizenship, () => {
  stayFrom.value = null;
  stayTo.value = null;
});

const stayToStartYear = computed(() => {
  return new Date().getFullYear();
});

const stayToEndYear = computed(() => {
  return new Date().getFullYear() + 10;
});

const v$ = useVuelidate(
  {
    ...personalDataFormRules,
    stayFrom: {
      required: helpers.withMessage(
        validationJson.required,
        requiredIf(showStayFrom),
      ),
      ...personalDataFormRules.stayFrom,
    },
    stayTo: {
      required: helpers.withMessage(
        validationJson.required,
        requiredIf(showStayTo),
      ),
      ...personalDataFormRules.stayTo,
    },
  },
  {
    citizenship,
    maritalStatus,
    stayFrom,
    stayTo,
    showStayFrom,
    showStayTo,
  },
);

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm class="personal-data-container">
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="submit">
        <div
          class="w-full lg:w-1/3 lg:pr-4"
          :class="{ 'mb-4 sm:mb-0 sm:mt-6': showStayFrom }"
        >
          <Select
            v-model="citizenship"
            :values="citizenshipOptions"
            :label="locale.citizenship"
            :disable-zero-value="true"
            floating
            :error="v$.citizenship.$error"
            :error-message="Validator.message(v$.citizenship)"
            test-id="citizenship"
          />
        </div>
        <div v-if="showStayFrom" class="w-full lg:w-1/3 mb-4 sm:mb-0">
          <label class="text-xs">{{ locale.residence_permit_since }}</label>
          <SelectDate
            v-model="stayFrom"
            :show-day="false"
            :start-year="2000"
            :error="v$.stayFrom.$error"
            :error-message="Validator.message(v$.stayFrom)"
            test-id="stayFrom"
          />
        </div>
        <div
          class="w-full lg:w-1/3 lg:pl-4 lg:pr-4"
          :class="{ hidden: !showStayTo }"
        >
          <label class="text-xs whitespace-no-wrap">
            {{ locale.residence_permit_expiration }}
          </label>
          <SelectDate
            v-model="stayTo"
            :start-year="stayToStartYear"
            :end-year="stayToEndYear"
            :error="v$.stayTo.$error"
            :error-message="Validator.message(v$.stayTo)"
            test-id="stayTo"
          />
        </div>
        <template #after>
          <MaritalStatus
            v-model="maritalStatus"
            :has-error="v$.maritalStatus.$error"
            :error-message="Validator.message(v$.maritalStatus)"
          />
        </template>
        <template #link>
          <BackButton :event="`goBack`" @go-back="$emit('goBack')" />
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
