<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import RadioButton from "~/components/inputs/RadioButton.vue";

import Validator from "~/utils/validator";

const locale = await getLocale("forms", "loan_protection_consent");
import { useVuelidate } from "@vuelidate/core";
import loanProtectionConsentFormRules from "~/rules/loanProtectionConsent";

defineComponent({
  name: "LoanProtectionConsent",
});

const props = defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  package: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "goBack"]);

const consentMain = useState<boolean | null>("consentMain", () => null);
const consent1 = useState<boolean | null>("consent1", () => null);
const consent2 = useState<boolean | null>("consent2", () => null);
const consent3 = useState<boolean | null>("consent3", () => null);
const consent4 = useState<boolean | null>("consent4", () => null);
const consent5 = useState<boolean | null>("consent5", () => null);
const consent6 = useState<boolean | null>("consent6", () => null);
const consent7 = useState<boolean | null>("consent7", () => null);

const gPackage = computed(() => {
  return props.package == "G";
});

const submitDisabled = computed(() => {
  const radiobuttonsRequired = [consentMain, consent1, consent2, consent3];

  if (gPackage) {
    radiobuttonsRequired.push(...[consent4, consent5, consent6, consent7]);
  }

  return radiobuttonsRequired.some((radiobutton) => !radiobutton.value);
});

watch(consentMain, async (newValue) => {
  if (newValue) {
    [
      consent1,
      consent2,
      consent3,
      consent4,
      consent5,
      consent6,
      consent7,
    ].forEach((radiobutton) => (radiobutton.value = true));
  }
});

const v$ = useVuelidate(loanProtectionConsentFormRules(gPackage.value), {
  consentMain,
  consent1,
  consent2,
  consent3,
  consent4,
  consent5,
  consent6,
  consent7,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard
        :title="title"
        :submit-disabled="submitDisabled"
        @submit="submit"
      >
        <p class="mb-4">
          {{ locale.text1 }}
        </p>
        <div class="mb-8">
          <RadioButton
            v-model="consentMain"
            :values="yesNoOptions"
            :error="
              v$.consentMain.$error || (consentMain !== null && !consentMain)
            "
            :error-message="
              v$.consentMain.$error
                ? Validator.message(v$.consentMain)
                : subscription_is_not_possible
            "
            data-testid="consentMain"
          >
            <div
              class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
              v-html="locale.consent_main"
            ></div>
          </RadioButton>
        </div>
        <p class="mb-4">
          {{ locale.text2 }}
        </p>
        <template v-if="gPackage">
          <div class="mb-8">
            <RadioButton
              v-model="consent1"
              :values="yesNoOptions"
              :error="v$.consent1.$error || (consent1 !== null && !consent1)"
              :error-message="
                v$.consent1.$error
                  ? Validator.message(v$.consent1)
                  : subscription_is_not_possible
              "
              data-testid="consent1"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option1.consent1"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent2"
              :values="yesNoOptions"
              :error="v$.consent2.$error || (consent2 !== null && !consent2)"
              :error-message="
                v$.consent2.$error
                  ? Validator.message(v$.consent2)
                  : subscription_is_not_possible
              "
              data-testid="consent2"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option1.consent2"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent3"
              :values="yesNoOptions"
              :error="v$.consent3.$error || (consent3 !== null && !consent3)"
              :error-message="
                v$.consent3.$error
                  ? Validator.message(v$.consent3)
                  : subscription_is_not_possible
              "
              data-testid="consent3"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option1.consent3"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent4"
              :values="yesNoOptions"
              :error="v$.consent4.$error || (consent4 !== null && !consent4)"
              :error-message="
                v$.consent4.$error
                  ? Validator.message(v$.consent4)
                  : subscription_is_not_possible
              "
              data-testid="consent4"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option1.consent4"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent5"
              :values="yesNoOptions"
              :error="v$.consent5.$error || (consent5 !== null && !consent5)"
              :error-message="
                v$.consent5.$error
                  ? Validator.message(v$.consent5)
                  : subscription_is_not_possible
              "
              data-testid="consent5"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option1.consent5"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent6"
              :values="yesNoOptions"
              :error="v$.consent6.$error || (consent6 !== null && !consent6)"
              :error-message="
                v$.consent6.$error
                  ? Validator.message(v$.consent6)
                  : subscription_is_not_possible
              "
              data-testid="consent6"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option1.consent6"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent7"
              :values="yesNoOptions"
              :error="v$.consent7.$error || (consent7 !== null && !consent7)"
              :error-message="
                v$.consent7.$error
                  ? Validator.message(v$.consent7)
                  : subscription_is_not_possible
              "
              data-testid="consent7"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option1.consent7"
              ></div>
            </RadioButton>
          </div>
        </template>
        <template v-else>
          <div class="mb-8">
            <RadioButton
              v-model="consent1"
              :values="yesNoOptions"
              :error="v$.consent1.$error || (consent1 !== null && !consent1)"
              :error-message="
                v$.consent1.$error
                  ? Validator.message(v$.consent1)
                  : subscription_is_not_possible
              "
              data-testid="consent1"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option2.consent1"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent2"
              :values="yesNoOptions"
              :error="v$.consent2.$error || (consent2 !== null && !consent2)"
              :error-message="
                v$.consent2.$error
                  ? Validator.message(v$.consent2)
                  : subscription_is_not_possible
              "
              data-testid="consent2"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option2.consent2"
              ></div>
            </RadioButton>
          </div>
          <div class="mb-8">
            <RadioButton
              v-model="consent3"
              :values="yesNoOptions"
              :error="v$.consent3.$error || (consent3 !== null && !consent3)"
              :error-message="
                v$.consent3.$error
                  ? Validator.message(v$.consent3)
                  : subscription_is_not_possible
              "
              data-testid="consent3"
            >
              <div
                class="w-full sm:flex-grow order-1 sm:order-3 mb-2"
                v-html="locale.option2.consent3"
              ></div>
            </RadioButton>
          </div>
        </template>
        <template #link>
          <BackButton
            class="!py-2"
            :event="`goBack`"
            @go-back="$emit('goBack')"
          />
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
