<script setup lang="ts">
import FormCard from "~/components/ui/FormCard.vue";
import BaseForm from "~/components/ui/BaseForm.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";

import ClubOptionalConsents from "~/components/inputs/ClubOptionalConsents.vue";

const locale = await getLocale("forms", "club_light");

defineProps({
  progress: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["init", "submit", "decline"]);

const consentMain = useState<boolean | null>(
  "clubLightConsentMain",
  () => null,
);
const consent1 = useState<boolean | null>("clubLightConsent1", () => null);
const consent2 = useState<boolean | null>("clubLightConsent2", () => null);
const consent3 = useState<boolean | null>("clubLightConsent3", () => null);
const consent4 = useState<boolean | null>("clubLightConsent4", () => null);

const submit = () => {
  emit("submit");
  return;
};
onMounted(() => emit("init"));
</script>

<template>
  <div>
    <BaseForm @submit.prevent="submit">
      <template #upper>
        <ProgressBar :value="progress" />
        <FormCard :title="locale.title">
          <p
            class="mb-4"
            v-html="locale.paragraph1.replace('%d', CLUB_LIGHT_INFO_URL)"
          ></p>
          <p
            v-html="
              locale.paragraph2
                .replace('%d', CLUB_LIGHT_TERMS_URL)
                .replace('%z', CLUB_PRIVACY_URL)
            "
          ></p>
          <p
            class="mb-4"
            v-html="
              locale.paragraph3
                .replace('%d', CLUB_LIGHT_TERMS_URL)
                .replace('%z', CLUB_PRIVACY_URL)
            "
          ></p>
          <ClubOptionalConsents
            v-model:main-consent="consentMain"
            v-model:consent1="consent1"
            v-model:consent2="consent2"
            v-model:consent3="consent3"
            v-model:consent4="consent4"
          />
          <template #link>
            <div class="text-center text-gray-700 mt-4 mb-8">
              <button
                data-testid="decline"
                class="underline inline"
                @click="emit('decline')"  
              >{{ locale.decline }}</button
              >
            </div>
          </template>
        </FormCard>
      </template>
    </BaseForm>
  </div>
</template>

<style scoped></style>
