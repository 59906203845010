<script setup lang="ts">
import ErrorMessage from "~/components/ui/ErrorMessage.vue";

defineProps({
  error: { type: Boolean, default: false },
  errorMessage: { type: String, default: "" },
});

const emit = defineEmits(["change"]);

const modelValue = defineModel<any>();

const updateModel = (value: any) => {
  modelValue.value = value;
  emit("change");
};

const options = identityDocOptions;
</script>

<template>
  <div
    class="identity-card flex flex-wrap justify-between w-full lg:w-2/3 gap-4"
  >
    <div
      v-for="{ value, label, icon } in options"
      :key="value"
      class="card mx-auto"
      :data-testid="`identityCard${label}`"
      @click="updateModel(value)"
      @keydown="e => { e.key === 'Enter' && updateModel(value) }"
      role="button"
      :aria-selected="value == modelValue"
      tabindex="0"
    >
      <div
        class="option"
        :class="[
          { 'border border-red-1': error },
          value == modelValue ? 'primary' : 'bg-gray-1',
        ]"
      >
        <div class="h-16">
          <component :is="icon" size="64px"></component>
        </div>
      </div>
      <span
        class="block text-center text-xs sm:text-base h-8 sm:h-auto mt-2"
        v-html="label"
      ></span>
    </div>
  </div>
  <div class="text-center mb-10">
    <ErrorMessage v-if="error" :message="errorMessage" />
  </div>
</template>

<style scoped>
.card {
  @apply cursor-pointer flex flex-col rounded-lg px-0 py-4;
}

.card .option {
  @apply h-16 w-16 mx-auto rounded-full flex items-center justify-center;
}
</style>
