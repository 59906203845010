<script setup lang="ts">
import Checkbox from "~/components/inputs/Checkbox.vue";
import Accordion from "~/components/ui/Accordion.vue";
const local = await getLocale("forms", "club_consent");
import { CLUB_PRIVACY_URL } from "~/utils/constants";

const mainClicked = ref<boolean>();

const mainConsent = defineModel<boolean | null>("mainConsent");
const consent1 = defineModel<boolean | null>("consent1");
const consent2 = defineModel<boolean | null>("consent2");
const consent3 = defineModel<boolean | null>("consent3");
const consent4 = defineModel<boolean | null>("consent4");

watch(mainConsent, (newVal) => {
  if (mainClicked.value) {
    consent1.value = newVal;
    consent2.value = newVal;
    consent3.value = newVal;
    consent4.value = newVal;
  }
});

watch([consent1, consent2, consent3, consent4], ([mc, pp, cs, pc]) => {
  mainClicked.value = false;
  mainConsent.value = mc && pp && cs && pc;
});
</script>

<template>
  <Accordion>
    <Checkbox
      v-model="mainConsent"
      test-id="mainConsent"
      @click="() => (mainClicked = true)"
    >
      <div data-testid="consentAccordion" v-html="local.accordion_text"></div>
    </Checkbox>

    <template #items>
      <div class="text-xs pt-4 pl-8">
        <Checkbox v-model="consent1" class="mb-4" test-id="consent1">
          <div
            v-html="
              local.marketing_communications.replace('%d', CLUB_PRIVACY_URL)
            "
          ></div>
        </Checkbox>

        <Checkbox v-model="consent2" class="mb-4" test-id="consent2">
          <div
            v-html="
              local.personalized_preferences.replace('%d', CLUB_PRIVACY_URL)
            "
          ></div>
        </Checkbox>

        <Checkbox v-model="consent3" class="mb-4" test-id="consent3">
          <div
            v-html="local.contact_sharing.replace('%d', CLUB_PRIVACY_URL)"
          ></div>
        </Checkbox>

        <Checkbox v-model="consent4" class="mb-4" test-id="consent4">
          <div
            v-html="local.profiling_consent.replace('%d', CLUB_PRIVACY_URL)"
          ></div>
        </Checkbox>
      </div>
    </template>
  </Accordion>
</template>

<style scoped></style>
