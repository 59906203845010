import { helpers, required } from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";

export default {
  installmentDebitDay: {
    required: helpers.withMessage(validationJson.required, required),
  },
  politicallyExposedPerson: {
    required: helpers.withMessage(validationJson.required, required),
  },
  iban: {
    required: helpers.withMessage(validationJson.required, required),
    validIBAN: helpers.withMessage(
      validationJson.invalid_iban,
      Validator.validIban,
    ),
    notPrepaidIBAN: helpers.withMessage(
      validationJson.invalid_iban,
      Validator.notPrepaidIban,
    ),
  },
};
