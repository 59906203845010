<script setup lang="ts">
import BaseForm from "../ui/BaseForm.vue";
import ProgressBar from "../ui/ProgressBar.vue";
import FormCard from "~/components/ui/FormCard.vue";
import Checkbox from "~/components/inputs/Checkbox.vue";
import ClubOptionalConsents from "~/components/inputs/ClubOptionalConsents.vue";
import ClubPlusConsent from "~/rules/clubPlusConsent";

const locale = await getLocale("forms", "club_plus_consent");
import { useVuelidate } from "@vuelidate/core";
import Validator from "~/utils/validator";
import { CLUB_PRIVACY_URL } from "~/utils/constants";

defineProps({
  progress: { type: Number, required: true },
});

const emit = defineEmits(["init", "submit", "decline", "goBack"]);

const consent_club1 = useState<boolean>("clubPlusConsent1", () => false);
const consent_club2 = useState<boolean>("clubPlusConsent2", () => false);
const consent_club3 = useState<boolean>("clubPlusConsent3", () => false);
const consent_club4 = useState<boolean>("clubPlusConsent4", () => false);

const consent_club5 = useState<boolean>("clubPlusConsent5", () => false);
const consent_club5a = useState<boolean>("clubPlusConsent5a", () => false);
const consent_club5b = useState<boolean>("clubPlusConsent5b", () => false);
const consent_club5c = useState<boolean>("clubPlusConsent5c", () => false);
const consent_club5d = useState<boolean>("clubPlusConsent5d", () => false);

const v$ = useVuelidate(ClubPlusConsent, {
  consent_club1,
  consent_club2,
  consent_club3,
  consent_club4,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"));
</script>

<template>
  <div>
    <BaseForm>
      <template #upper>
        <ProgressBar :value="progress" />

        <FormCard
          :title="locale.title"
          :header-class="'!text-base sm:!text-3xl'"
          @submit="submit"
        >
          <p
            class="mb-4"
            v-html="locale.paragraph.replace('%d', CLUB_PRIVACY_URL)"
          ></p>

          <div class="flex flex-col gap-4">
            <Checkbox
              v-model="consent_club1"
              :error="v$.consent_club1.$error"
              :error-message="Validator.message(v$.consent_club1)"
              test-id="clubPlusConsent1"
            >
              <span
                class="text-sm"
                v-html="
                  locale.consents.consent1.replace('%d', CLUB_PLUS_TERMS_URL)
                "
              >
              </span>
            </Checkbox>
            <Checkbox
              v-model="consent_club2"
              :error="v$.consent_club2.$error"
              :error-message="Validator.message(v$.consent_club2)"
              test-id="clubPlusConsent2"
            >
              <span class="text-sm" v-html="locale.consents.consent2"></span>
            </Checkbox>
            <Checkbox
              v-model="consent_club3"
              :error="v$.consent_club3.$error"
              :error-message="Validator.message(v$.consent_club3)"
              test-id="clubPlusConsent3"
            >
              <span class="text-sm" v-html="locale.consents.consent3"></span>
            </Checkbox>
            <Checkbox
              v-model="consent_club4"
              :error="v$.consent_club4.$error"
              :error-message="Validator.message(v$.consent_club4)"
              test-id="clubPlusConsent4"
            >
              <span class="text-sm" v-html="locale.consents.consent4"></span>
            </Checkbox>

            <ClubOptionalConsents
              v-model:main-consent="consent_club5"
              v-model:consent1="consent_club5a"
              v-model:consent2="consent_club5b"
              v-model:consent3="consent_club5c"
              v-model:consent4="consent_club5d"
            />
          </div>

          <template #link>
            <div class="text-center text-gray-700 mt-4 mb-8">
              <button class="underline" @click="emit('goBack')">
                {{ locale.go_back }}
              </button>
            </div>
          </template>
        </FormCard>
        <div class="spacer h-6"></div>
      </template>
    </BaseForm>
  </div>
</template>

<style scoped></style>
