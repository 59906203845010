<script setup lang="ts">

import autocomplete from "autocompleter";

import type { IValue } from "~/interfaces";
import Fuse from "fuse.js";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";

defineComponent({
  name: "AutoComplete",
});

const { values, delimiter, clearIncomplete, uppercase } = defineProps({
  name: { type: String, required: false },
  label: { type: String, default: "", required: true },
  disabled: { type: Boolean, default: false },
  delimiter: { type: String, default: null, required: false },
  error: { type: Boolean, default: false },
  errorMessage: { type: String, default: "" },
  values: { type: Array as () => IValue[], default: () => [], required: true },
  clearIncomplete: { type: Boolean, default: false, required: false },
  uppercase: { type: Boolean, default: true },
  testId: { type: String, default: "" },
  type: { type: String, default: "text" },
});
const value = defineModel({
  type: String,
  default: "",
});

const uid = getCurrentInstance()?.uid;

const elementsToSearch = new Fuse(values, {
  keys: ["label"],
  threshold: 0.0,
  location: 0,
  distance: 0,
  minMatchCharLength: 0,
  isCaseSensitive: false,
});

const inputRef = ref<HTMLInputElement | null>(null);
const listRef = ref<HTMLDivElement | undefined>();
const match = ref<boolean>(false);
const select = ref<boolean>(false);
const inputValue = ref<string>('');

defineEmits(["input"]);

watch(value, (newValue) => {
  inputValue.value = newValue;
});

onMounted(() => {
  if (inputRef.value) {
    inputValue.value = value.value;

    const engine = autocomplete({
      input: inputRef.value,
      debounceWaitMs: 200,
      fetch: function (term: string, update: (data: any[]) => void) {
        select.value = false;
        update(search(term))
      },
      container: listRef.value,
      render: function(item, currentValue) {
          const div = document.createElement('div');
          div.innerHTML = `<b>${currentValue}</b>${item.slice(currentValue.length)}`;
          div.role = 'option'
          div.classList.add('autocomplete-suggestion')
          uppercase && div.classList.add('uppercase')
          return div;
      },
      onSelect: function (term) {
        select.value = true;
        inputValue.value = term;
        value.value = term;
      },
      preventSubmit: 1,
    });
    onBeforeUnmount(() => {
      engine.destroy();
    });
  }
});

const handleBlur = (e: FocusEvent) => {
  if (!clearIncomplete) {
    value.value = inputValue.value
  } else {
    if (
      (inputValue.value !== value.value)
      || (!match.value || !select.value)
    ) {
      value.value = "";
      inputValue.value = "";
    }
  }
};

const handleInput = () => {
  formatInput();
  if (!clearIncomplete) {
    value.value = inputValue.value
  }
}

const search = (term: string): string[] => {
  match.value = true;

  const searchAndMapResults = (
    searchTerm: string,
    mapFn: (result: any) => string,
  ): string[] => {
    const results = elementsToSearch.search(searchTerm);

    if (clearIncomplete) {
      if (results.length === 0) {
        match.value = false;
      }
    }

    return results.map(mapFn);
  };

  if (delimiter != null) {
    if (!term.includes(delimiter)) return [];

    const [name, domain] = term.split(delimiter);

    if (!domain) {
      return (elementsToSearch._docs as IValue[]).map(
        (result: IValue) => `${name}${delimiter}${result.value}`,
      );
    }

    return searchAndMapResults(
      domain,
      (result: any) => `${name}${delimiter}${result.item.value}`,
    );
  }

  if (term.length < 2) {
    return [];
  }

  return searchAndMapResults(term, (result: any) => result.item.label);
};

const formatInput = () => {
  inputRef.value!.value = inputRef.value?.value.replace(/’/g, "'") ?? "";
};

</script>

<template>
  <div class="label-floating relative" :class="{ 'border-red': error }">
    <input
      :id="`autocomplete-${uid}`"
      ref="inputRef"
      v-model="inputValue"
      :type="type"
      :placeholder="label"
      :disabled="disabled"
      :class="['autocomplete-input primary', uppercase ? 'uppercase' : '']"
      @blur="handleBlur"
      @input="handleInput"
      role="combobox"
      aria-autocomplete="list"
      aria-haspopup="listbox"
      :aria-expanded="!!select"
      :aria-owns="`autocomplete-list-${uid}`"
      autocomplete="off"
    />
    <label :for="`autocomplete-${uid}`">{{ label }}</label>
    <div class="relative">
      <div class="autocomplete-suggestions" ref="listRef" :id="`autocomplete-list-${uid}`" role="listbox" ></div>
    </div>
  </div>
  <ErrorMessage v-if="error" :message="errorMessage" />
</template>

<style scoped>
.autocomplete-input {
  @apply sm:h-12 placeholder:normal-case;
}
</style>
