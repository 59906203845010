<script setup lang="ts">
import TypFb from "~/components/icons/typ/typ-fb.vue";
import TypIg from "~/components/icons/typ/typ-ig.vue";

const locale = await getLocale("typ", "typ_club_ko");
const emit = defineEmits(["init"]);

defineComponent({
  name: "TypClubKO",
});

defineProps({
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
});

onMounted(() => emit("init"));
</script>

<template>
  <div
    class="w-11/12 sm:max-w-7xl mx-auto shadow-lg rounded-3xl bg-white mt-4 mb-10 lg:my-10"
  >
    <div
      class="px-4 mx-auto lg:container text-sm sm:text-base leading-tight text-gray-700"
    >
      <h3
        class="px-2 py-6 text-center text-2xl sm:text-3xl font-semibold"
        v-html="locale.header.replace('%d', name)"
      ></h3>
      <div class="max-w-4xl text-lg mx-auto p-4">
        <h1
          class="text-2xl font-semibold text-center mt-4 mb-8"
          v-html="locale.title.replace('%t', type)"
        ></h1>
        <p class="text-center mt-10" v-html="locale.thanks"></p>
        <div class="text-center mt-10">
          <p v-html="locale.follow_us"></p>
          <div class="social-links">
            <a
              class="inline-block social-link p-4"
              target="_blank"
              rel="nofollow"
              href="https://www.facebook.com/CofidisItalia/"
            >
              <TypFb class="h-12 mx-auto" />
            </a>
            <a
              class="inline-block social-link p-4"
              target="_blank"
              rel="nofollow"
              href="https://www.instagram.com/cofidisitalia/"
            >
              <TypIg class="h-12 mx-auto" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer h-6"></div>
  </div>
</template>

<style scoped></style>
