<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import FormCard from "~/components/ui/FormCard.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import InputFloatingLabel from "~/components/inputs/InputFloatingLabel.vue";
import AutoComplete from "~/components/inputs/AutoComplete.vue";
import Select from "~/components/inputs/Select.vue";
import RadioButton from "~/components/inputs/RadioButton.vue";
import jsonCities from "~/data/cities.json";

import Validator from "~/utils/validator";

import type { ICity, IValue } from "~/interfaces";
import type { Ref } from "vue";

const locale = await getLocale("forms", "employer_data");
import { useVuelidate } from "@vuelidate/core";
import employerFormRules from "~/rules/employerData";
import {
  addressMask,
  addressStreetNumberMask,
  upperCaseMask,
} from "~/utils/masks";

defineComponent({
  name: "EmployerData",
});

const props = defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  showEmployeeLoan: { type: Boolean, default: false },
  showFamilyMemberCount: { type: Boolean, default: true },
});

const emit = defineEmits(["init", "submit", "goBack"]);

const employerName = useState<string | null>("employerName", () => null);
const employerAddress = useState<string | null>("employerAddress", () => null);
const employerStreetNumber = useState<string | null>(
  "employerStreetNumber",
  () => null,
);
const employerCity = useState<string | null>("employerCity", () => null);
const employerPostalCode = useState<string | null>(
  "employerPostalCode",
  () => null,
);
const employerPhone = useState<string | null>("employerPhone", () => null);
const employeeLoan = useState<boolean | null>("employeeLoan", () => null);
const familyMemberCount = useState<number | null>(
  "familyMemberCount",
  () => null,
);
const employedFamilyMemberCount = useState<number | null>(
  "employedFamilyMemberCount",
  () => null,
);

const cities: Ref<IValue[]> = ref([]);
const postalCodes: Ref<IValue[]> = ref([]);

onMounted(async () => {
  jsonCities.forEach((city: ICity) => {
    cities.value.push({
      value: city.Prov,
      label: `${city.Citta} (${city.Prov})`,
    });
  });
  emit("init");
});

const familyMembersCountValues = computed(() => {
  const arr: IValue[] = [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => ({
    value: i,
    label: i.toString(),
  }));
  arr.unshift({
    value: null,
    label: locale.family_members_count,
    disabled: true,
  });
  return arr;
});

const employedFamilyMemberCountValues = computed(() => {
  const arr: IValue[] = [
    {
      value: null,
      label: locale.employed_family_members_count,
      disabled: true,
    },
  ];
  if (familyMemberCount.value) {
    for (let i = 1; i <= familyMemberCount.value; i++) {
      arr.push({ value: i, label: i.toString() });
    }
  }
  return arr;
});

const showEmployedFamilyMemberCount = computed(() => {
  if (familyMemberCount.value) {
    return familyMemberCount.value > 0;
  }
  return false;
});

watch(familyMemberCount, async (newValue) => {
  if (newValue === 1) {
    employedFamilyMemberCount.value = 1;
    return;
  }
  if (
    newValue &&
    employedFamilyMemberCount.value &&
    newValue < employedFamilyMemberCount.value
  ) {
    employedFamilyMemberCount.value = newValue;
  }
  if (v$.value.employedFamilyMemberCount.$error) {
    v$.value.employedFamilyMemberCount.$reset();
  }
});

watch(employerCity, async (newValue) => {
  employerPostalCode.value = null;
  postalCodes.value = [];

  const city = jsonCities.find((city) => city.Citta == newValue?.slice(0, -5));

  if (city) {
    if (city.Multicap) {
      postalCodes.value.push({
        value: null,
        label: locale.postal_code,
        disabled: true,
      });
      city.Multicap.split(",").map((cap: string) => {
        postalCodes.value.push({ value: cap, label: cap.padStart(5, "0") });
      });
    } else {
      employerPostalCode.value = city.Cap;
    }
  }
});

const v$ = useVuelidate(employerFormRules, {
  employerName,
  employerAddress,
  employerStreetNumber,
  employerCity,
  employerPostalCode,
  employerPhone,
  employeeLoan,
  familyMemberCount,
  employedFamilyMemberCount,
  showEmployeeLoan: props.showEmployeeLoan,
  showFamilyMemberCount: props.showFamilyMemberCount,
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="submit">
        <div class="w-full sm:w-1/2 sm:pr-4 mb-2">
          <InputFloatingLabel
            v-model="employerName"
            :label="locale.denomination"
            type="text"
            :mask="upperCaseMask"
            :error="v$.employerName.$error"
            :error-message="Validator.message(v$.employerName)"
            test-id="employerName"
          />
        </div>
        <div class="w-2/3 sm:w-1/3 sm:pr-4 mb-2">
          <InputFloatingLabel
            v-model="employerAddress"
            :label="locale.address"
            type="text"
            :mask="addressMask"
            :error="v$.employerAddress.$error"
            :error-message="Validator.message(v$.employerAddress)"
            test-id="employerAddress"
          />
        </div>
        <div class="w-1/3 pl-4 sm:pl-0 sm:pr-0 sm:w-1/6 mb-2">
          <InputFloatingLabel
            v-model="employerStreetNumber"
            :label="locale.street_number"
            type="text"
            :mask="addressStreetNumberMask"
            :error="v$.employerStreetNumber.$error"
            :error-message="Validator.message(v$.employerStreetNumber)"
            test-id="employerStreetNumber"
          />
        </div>
        <div class="w-2/3 sm:w-1/2 sm:pr-4 mb-2">
          <AutoComplete
            v-if="cities.length"
            v-model="employerCity"
            :values="cities"
            :clear-incomplete="true"
            :label="locale.city"
            :error="v$.employerCity.$error"
            :error-message="Validator.message(v$.employerCity)"
            test-id="employerCity"
          />
        </div>
        <div class="w-1/3 sm:w-1/6 pl-4 sm:pl-0 sm:pr-4 mb-2">
          <Select
            v-if="postalCodes.length"
            v-model="employerPostalCode"
            :values="postalCodes"
            :label="locale.postal_code"
            floating
            disable-zero-value
            :error="v$.employerPostalCode.$error"
            :error-message="Validator.message(v$.employerPostalCode)"
            test-id="employerPostalCode"
          />
          <InputFloatingLabel
            v-else
            v-model="employerPostalCode"
            :label="locale.postal_code"
            type="text"
            :error="v$.employerPostalCode.$error"
            :error-message="Validator.message(v$.employerPostalCode)"
            disabled
            test-id="employerPostalCode"
          />
        </div>
        <div class="w-full sm:w-1/3 mb-2">
          <InputFloatingLabel
            v-model="employerPhone"
            :label="locale.phone"
            type="tel"
            :mask="phoneMask"
            :error="v$.employerPhone.$error"
            :error-message="Validator.message(v$.employerPhone)"
            test-id="employerPhone"
          />
        </div>
        <div v-if="showEmployeeLoan" class="w-full mb-4 text-center mt-2">
          <label>{{ locale.salary_loan }}</label>
          <div class="flex justify-center mt-3">
            <RadioButton
              v-model="employeeLoan"
              :values="yesNoOptions"
              :error="v$.employeeLoan.$error"
              :error-message="Validator.message(v$.employeeLoan)"
              data-testid="employeeLoan"
            />
          </div>
        </div>
        <div
          v-if="showFamilyMemberCount"
          class="w-full md:w-full lg:w-1/2 xl:w-1/3 lg:pr-4 mb-2"
        >
          <Select
            v-model="familyMemberCount"
            :values="familyMembersCountValues"
            :label="locale.family_members_count"
            floating
            disable-zero-value
            :error="v$.familyMemberCount.$error"
            :error-message="Validator.message(v$.familyMemberCount)"
            test-id="familyMemberCount"
          />
        </div>
        <div
          v-if="showEmployedFamilyMemberCount"
          class="w-full md:w-full lg:w-1/2 xl:w-1/3 xl:pr-4 mb-2"
        >
          <Select
            v-model="employedFamilyMemberCount"
            :values="employedFamilyMemberCountValues"
            :label="locale.employed_family_members_count"
            floating
            disable-zero-value
            :error="v$.employedFamilyMemberCount.$error"
            :error-message="Validator.message(v$.employedFamilyMemberCount)"
            test-id="employedFamilyMemberCount"
          />
        </div>
        <template #link>
          <BackButton :event="`goBack`" @go-back="$emit('goBack')" />
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
