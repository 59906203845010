<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import FormCard from "~/components/ui/FormCard.vue";

defineProps({
  title: { type: String, required: true },
  text: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "goBack"]);
onMounted(() => {
  updateStateValue("promoModal", false);
  emit("init");
});
</script>

<template>
  <BaseForm>
    <template #out>
      <div class="w-11/12 sm:max-w-7xl mx-auto -mt-8 sm:-mt-4 pt-12 px-2">
        <span
          data-testid="goBack"
          class="cursor-pointer"
          @click="$emit('goBack')"
        >
          <svg
            width="17"
            height="14"
            viewBox="0 0 17 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="inline-block mr-2"
          >
            <path
              d="M16 7.84515C16.4668 7.84515 16.8452 7.46677 16.8452 7C16.8452 6.53323 16.4668 6.15485 16 6.15485V7.84515ZM0.402386 6.40239C0.0723324 6.73244 0.0723324 7.26756 0.402386 7.59761L5.78091 12.9761C6.11097 13.3062 6.64609 13.3062 6.97614 12.9761C7.3062 12.6461 7.3062 12.111 6.97614 11.7809L2.19523 7L6.97614 2.21909C7.3062 1.88903 7.3062 1.35391 6.97614 1.02386C6.64609 0.693803 6.11097 0.693803 5.78091 1.02386L0.402386 6.40239ZM16 6.15485L1 6.15485V7.84515L16 7.84515V6.15485Z"
              fill="black"
            />
          </svg>
          <span class="text-sm font-semibold">Torna all'offerta</span>
        </span>
      </div>
    </template>

    <template #upper>
      <FormCard :title="title" :has-submit="false" @submit="emit('submit')">
        <div
          class="res-dynamic-content w-full text-base pb-6"
          v-html="parseJsonUrls(text)"
        ></div>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style>
div.res-dynamic-content > p {
  @apply mt-4 !important;
}
div.res-dynamic-content > ul {
  @apply list-disc px-4 my-2 !important;
}
div.res-dynamic-content > ul > li {
  @apply mt-2 !important;
}
</style>
