<script setup lang="ts">
import Options from "inputmask";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";

type InputType = "text" | "date" | "tel" | "email";

defineComponent({
  name: "Input",
});

const { name, type, label, disabled, error } = defineProps({
  name: {
    type: String,
    required: false,
  },
  type: {
    type: String as () => InputType,
    default: "text",
    required: true,
  },
  label: {
    type: String,
    default: "",
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: "",
  },
  theme: {
    type: String,
    default: "primary",
  },
  mask: {
    type: Object as () => Options.Options,
    default: {},
  },
  testId: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["change"]);

const value = defineModel<any>();

const uid = getCurrentInstance()?.uid;

</script>

<template>
  <div :class="[{ 'border-red': error }, 'label-floating', theme]">
    <input
      v-model="value"
      v-mask="mask"
      :value="value"
      :name="name"
      :type="type"
      :placeholder="label"
      :disabled="disabled"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      :class="`${theme}`"
      :data-testid="testId"
      :data-mask="mask != undefined"
      :id="`input-floating-${uid}`"
      @change="emit('change')"
    />
    <label :for="`input-floating-${uid}`">{{ label }}</label>
  </div>
  <ErrorMessage v-if="error" :message="errorMessage" />
</template>

<style scoped></style>
