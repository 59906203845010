import {
  email as emailValidator,
  helpers,
  required,
} from "@vuelidate/validators";
import validationJson from "~/static/locale/it/validation.json";
import Validator from "~/utils/validator";

export default {
  email: {
    required: helpers.withMessage(validationJson.required, required),
    email: helpers.withMessage(validationJson.invalid_email, emailValidator),
  },
  cellphone: {
    required: helpers.withMessage(validationJson.required, required),
    validPhone: helpers.withMessage(
      validationJson.invalid_phone,
      Validator.cellphone,
    ),
  },
  taxCode: {
    required: helpers.withMessage(validationJson.required, required),
    tax_code_valid: helpers.withMessage(
      validationJson.invalid_tax_code,
      Validator.taxCode,
    ),
    tax_code_age: helpers.withMessage(
      validationJson.of_legal_age,
      Validator.taxCodeAge,
    ),
    tax_code_name: helpers.withMessage(
      validationJson.incongruent_tax_code,
      Validator.taxCodeName,
    ),
  },
  name: { required: helpers.withMessage(validationJson.required, required) },
  surname: { required: helpers.withMessage(validationJson.required, required) },
};
