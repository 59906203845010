<script setup lang="ts">
import CheckboxTick from "~/components/icons/checkbox/CheckboxTick.vue";
import ErrorMessage from "~/components/ui/ErrorMessage.vue";

const { name, disabled, error, errorMessage } = defineProps({
  name: { type: String, required: false },
  disabled: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  errorMessage: { type: String, default: "" },
  testId: { type: String, default: "" },
});

const emits = defineEmits(["change"]);

const value = defineModel<boolean | null>();

watch(value, (value) => {
  emits("change", value);
});

const uid = getCurrentInstance()?.uid;

const handleKeydown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') 
    value.value = !value.value
}

</script>

<template>
  <div :class="['small-checkbox primary', { error: error }]">
    <div class="checkbox-container">
      <CheckboxTick v-if="value === true" class="checkbox-thick" />
      <input
        v-model="value"
        :id="`checkbox-${uid}`"
        type="checkbox"
        :name="name"
        class="appearance-none"
        :class="[{ disabled: disabled }, 'primary']"
        :disabled="disabled"
        :checked="value"
        :data-testid="testId"
        @keydown="handleKeydown"
      />
    </div>
    <div class="flex flex-col items-center ml-4">
      <label :for="`checkbox-${uid}`" class="inline-block">
        <slot></slot>
      </label>
      <ErrorMessage v-if="error" :message="errorMessage" />
    </div>
  </div>
</template>

<style scoped>
.small-checkbox {
  @apply flex items-start;
}

.small-checkbox label {
  @apply cursor-pointer;
}

.small-checkbox .checkbox-container {
  @apply flex items-center justify-center relative;
}

.small-checkbox input[type="checkbox"] {
  @apply h-[24px];
}

.small-checkbox input[type="checkbox"]:before {
  @apply relative block w-[24px] h-[24px] content-[""] border border-gray-5 rounded-[4px] cursor-pointer;
}

.small-checkbox input[type="checkbox"]:not(:checked):before {
  @apply bg-white;
}

.small-checkbox.error {
  @apply relative;
}

.small-checkbox.error label {
  @apply pb-[1.5em];
}

.small-checkbox.error input[type="checkbox"]:before {
  @apply border border-red-1;
}

.checkbox-thick {
  @apply content-[''] h-[24px] w-[24px] p-1 absolute pointer-events-none z-10;
}

.disabled {
  @apply opacity-75;
}
</style>
