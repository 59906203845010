<script setup lang="ts">
import BaseForm from "~/components/ui/BaseForm.vue";
import FormCard from "~/components/ui/FormCard.vue";
import ProgressBar from "~/components/ui/ProgressBar.vue";
const locale = await getLocale("forms", "loan_protection");
import { INSURANCE_PACKAGES } from "~/utils/constants";

defineComponent({
  name: "LoanProtection",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
  package: { type: String, required: true },
  rate: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "decline"]);
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="emit('submit')">
        <template v-if="package == INSURANCE_PACKAGES.G">
          <p
            class="my-4"
            v-html="
              locale.option1.text1.replace('%d', formatNumber(rate, null, ' '))
            "
          ></p>
          <p class="my-4" v-html="locale.option1.text2"></p>
        </template>
        <template v-else>
          <p
            class="mt-4"
            v-html="
              locale.option2.text1.replace('%d', formatNumber(rate, null, ' '))
            "
          ></p>
          <p class="my-4" v-html="locale.option2.text2"></p>
        </template>
        <template #link>
          <div class="text-center text-gray-700 text-sm mt-4 mb-8">
            <button
              data-testid="decline"
              class="underline inline"
              @click="$emit('decline')"
            >
              {{ locale.proceed_without_protection }}
            </button>
          </div>
        </template>
      </FormCard>
      <div class="spacer h-6"></div>
    </template>

    <template #bottom-out>
      <p class="italic px-4">{{ locale.disclaimer }}</p>
    </template>
  </BaseForm>
</template>

<style scoped></style>
