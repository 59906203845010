<script setup lang="ts">
import Validator from "~/utils/validator";
import ProgressBar from "~/components/ui/ProgressBar.vue";
import BaseForm from "~/components/ui/BaseForm.vue";
import FormCard from "~/components/ui/FormCard.vue";
import RadioButton from "~/components/inputs/RadioButton.vue";
import BackButton from "~/components/inputs/BackButton.vue";
import Select from "~/components/inputs/Select.vue";
const locale = await getLocale("forms", "nucleo_familiare");
import { useVuelidate } from "@vuelidate/core";
import familyIncomeFormRules from "~/rules/familyIncome";
import { helpers, requiredIf } from "@vuelidate/validators/dist";
import validationJson from "~/static/locale/it/validation.json";

defineComponent({
  name: "FamilyIncome",
});

defineProps({
  progress: { type: Number, required: true },
  title: { type: String, required: true },
});

const emit = defineEmits(["init", "submit", "goBack"]);

const familyIncome = useState<boolean | null>("familyIncome", () => null);
const familyIncomeActivity = useState<number | null>(
  "familyIncomeActivity",
  () => null,
);
const showFamilyIncomeActivity = computed(() => {
  return familyIncome.value;
});

const v$ = useVuelidate(familyIncomeFormRules, {
  familyIncome,
  familyIncomeActivity,
});

watch(familyIncome, async (value) => {
  familyIncomeActivity.value = 0;
  v$.value.$reset();
});

const validate = () => {
  v$.value.$touch();
  v$.value.$validate();
  return !v$.value.$error;
};

const submit = () => {
  if (validate()) {
    emit("submit");
  }
};
onMounted(() => emit("init"));
</script>

<template>
  <BaseForm>
    <template #upper>
      <ProgressBar :value="progress" />
      <FormCard :title="title" @submit="submit">
        <div class="w-full mb-8">
          <label class="text-base">{{ locale.text }}</label>
          <div class="flex mt-6 justify-center">
            <RadioButton
              v-model="familyIncome"
              :values="yesNoOptions"
              :error="v$.familyIncome.$error"
              :error-message="Validator.message(v$.familyIncome)"
              data-testid="familyIncome"
            />
          </div>
        </div>
        <div v-if="familyIncome" class="w-full sm:w-1/2 sm:pr-4 mb-6">
          <Select
            v-model="familyIncomeActivity"
            :values="familyIncomeActivities"
            :label="locale.employment_type"
            floating
            disable-zero-value
            :error="v$.familyIncomeActivity.$error"
            :error-message="Validator.message(v$.familyIncomeActivity)"
            test-id="familyIncomeActivity"
          />
        </div>
        <template #link>
          <BackButton :event="`goBack`" @go-back="$emit('goBack')" />
        </template>
      </FormCard>
    </template>
  </BaseForm>
</template>

<style scoped></style>
